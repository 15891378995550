@font-face {
  src: url("../../../public/fonts/ProximaNova-Regular.woff2") format("woff2");
  font-family: "ProximaNova";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url("../../../public/fonts/ProximaNova-Semibold.woff2") format("woff2");
  font-family: "ProximaNova";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url("../../../public/fonts/ProximaNova-Bold.woff2") format("woff2");
  font-family: "ProximaNova";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url("../../../public/fonts/Lato-Regular.woff2") format("woff2");
  font-family: "Lato";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url("../../../public/fonts/Lato-Medium.woff2") format("woff2");
  font-family: "Lato";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url("../../../public/fonts/Lato-Semibold.woff2") format("woff2");
  font-family: "Lato";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url("../../../public/fonts/Lato-Bold.woff2") format("woff2");
  font-family: "Lato";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
